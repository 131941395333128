import Link from "next/link";

import { Instagram, YouTube } from "@mui/icons-material";
import { SxProps, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { tmrColorPalette } from "@taghere/common-muis";

import { LANDING_BUTTON_LINK, TAGHERE_ADMIN_HOST } from "@/consts/url";

const INSTAGRAM_URL = "https://www.instagram.com/taghere_official/";
const YOUTUBE_URL = "https://www.youtube.com/channel/UC51MDLywFTAY_-QDd5PBCOQ";
const email = "contact@tmr-founders.com";
const contactNum = "070-4138-0263";

const StyledLink = styled(Link)({
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    height: "auto",
});

/**
 * Footer 컴포넌트
 */
export default function Footer() {
    const MAX_WIDTH = 1200;
    const isMobile = useMediaQuery(`@media (max-width:${MAX_WIDTH}px)`);

    return (
        <Box
            component="footer"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor={tmrColorPalette.black[100]}
            sx={
                isMobile
                    ? {
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                      }
                    : {
                          justifyContent: "center",
                          alignItems: "center",
                      }
            }
        >
            <Box
                display="flex"
                sx={
                    isMobile
                        ? {
                              flexDirection: "column",
                              padding: "40px 0px 40px 40px",
                              gap: "50px",
                          }
                        : {
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "40px 120px",
                              width: "100%",
                              maxWidth: MAX_WIDTH,
                          }
                }
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    gap="30px"
                >
                    <StyledLink rel="noreferrer" target="_blank" href={"/"}>
                        <img
                            alt="태그히어 로고"
                            src="/assets/images/lp/logo_white.svg"
                            width={128}
                            height={"auto"}
                        />
                    </StyledLink>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Typography
                            variant="f12M"
                            color={tmrColorPalette.grey[400]}
                            lineHeight={1.5}
                            whiteSpace="nowrap"
                        >
                            상호 / 대표이사&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(주)티엠알파운더스 /
                            김영호
                        </Typography>
                        <Typography
                            variant="f12M"
                            color={tmrColorPalette.grey[400]}
                            lineHeight={1.5}
                            whiteSpace="nowrap"
                        >
                            사업자등록번호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;492-88-02374
                        </Typography>
                        <Typography
                            variant="f12M"
                            color={tmrColorPalette.grey[400]}
                            lineHeight={1.5}
                            whiteSpace="nowrap"
                        >
                            통신판매업신고번호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022-서울강남-05529
                        </Typography>
                        <StyledLink href={`mailto:${email}`}>
                            <Typography
                                variant="f12M"
                                color={tmrColorPalette.grey[400]}
                                lineHeight={1.5}
                                whiteSpace="nowrap"
                            >
                                이메일&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{email}
                            </Typography>
                        </StyledLink>
                        <StyledLink href={`tel:${contactNum.replaceAll("-", "")}`}>
                            <Typography
                                variant="f12M"
                                color={tmrColorPalette.grey[400]}
                                lineHeight={1.5}
                                whiteSpace="nowrap"
                            >
                                전화번호&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{contactNum}
                            </Typography>
                        </StyledLink>
                        <Typography
                            variant="f12M"
                            color={tmrColorPalette.grey[400]}
                            lineHeight={1.5}
                            whiteSpace="nowrap"
                        >
                            주소&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;서울 서초구 강남대로 53길 8, 6층
                            티엠알파운더스
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" gap="20px">
                        <StyledLink href={INSTAGRAM_URL} target="_blank">
                            <Instagram htmlColor={tmrColorPalette.grey[400]} />
                        </StyledLink>
                        <StyledLink href={YOUTUBE_URL} target="_blank">
                            <YouTube htmlColor={tmrColorPalette.grey[400]} />
                        </StyledLink>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    sx={
                        isMobile
                            ? {
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  gap: "30px",
                              }
                            : {
                                  justifyContent: "center",
                                  alignSelf: "flex-start",
                                  alignItems: "flex-start",
                                  gap: "50px",
                              }
                    }
                >
                    <StyledLink
                        href="https://tag-here.com/menu/seoulbrewery-seongsu/view"
                        target="_blank"
                    >
                        <Typography
                            variant="f14M"
                            color={tmrColorPalette.grey[400]}
                            whiteSpace="nowrap"
                        >
                            메뉴판 샘플
                        </Typography>
                    </StyledLink>
                    <StyledLink href={LANDING_BUTTON_LINK} target="_blank">
                        <Typography
                            variant="f14M"
                            color={tmrColorPalette.grey[400]}
                            whiteSpace="nowrap"
                        >
                            서비스 신청
                        </Typography>
                    </StyledLink>
                    <StyledLink href={TAGHERE_ADMIN_HOST} target="_blank">
                        <Typography
                            variant="f14M"
                            color={tmrColorPalette.grey[400]}
                            whiteSpace="nowrap"
                        >
                            어드민 페이지
                        </Typography>
                    </StyledLink>
                    <StyledLink
                        href="https://tmr-founders.notion.site/81fb99257a884f1991d853a693837a56?pvs=4"
                        target="_blank"
                    >
                        <Typography
                            variant="f14M"
                            color={tmrColorPalette.grey[400]}
                            whiteSpace="nowrap"
                        >
                            이용약관
                        </Typography>
                    </StyledLink>
                    <StyledLink
                        href="https://tmr-founders.notion.site/c8ddcee089e6489e8c2351ff459d3d80?pvs=4"
                        target="_blank"
                    >
                        <Typography
                            variant="f14M"
                            color={tmrColorPalette.grey[400]}
                            whiteSpace="nowrap"
                        >
                            개인정보 처리방침
                        </Typography>
                    </StyledLink>
                </Box>

                <Box
                    sx={
                        isMobile
                            ? {
                                  justifySelf: "flex-start",
                                  alignSelf: "flex-start",
                              }
                            : {
                                  justifySelf: "flex-end",
                                  alignSelf: "flex-end",
                              }
                    }
                >
                    <Typography
                        variant="f12M"
                        color={tmrColorPalette.grey[400]}
                        fontSize="11px"
                        whiteSpace="nowrap"
                    >
                        @tmrfounders Inc. All rights reserved.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
