import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

import { Box, SxProps, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
    tenHundMaxWidthMediaQuery,
} from "@taghere/common-utils";

interface LandingLayoutProps {
    children: React.ReactNode;
    sx?: SxProps;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ children, sx }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    const [isLandscape, setIsLandscape] = useState<boolean>(false);

    const mediaQueryList =
        typeof window !== "undefined"
            ? window?.matchMedia("(orientation:landscape)")
            : undefined;

    const handleOrientationChange = () => {
        if (!mediaQueryList?.matches) return;
        setIsLandscape((prev) => mediaQueryList.matches);
    };

    useEffect(() => {
        mediaQueryList?.addEventListener("change", handleOrientationChange);

        return () => {
            mediaQueryList?.removeEventListener("change", handleOrientationChange);
        };
    }, []);
    return (
        <Box
            sx={{
                maxWidth: "1440px",
                width: "100%",
                mx: "auto",
                marginY: "120px",
                padding: isMobile ? "30px" : "auto",
                paddingTop: isMobile ? "30px" : "100px",
                paddingBottom: isMobile ? "30px" : "100px",
                paddingX: isLandscape ? "30px" : "auto",
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export default LandingLayout;
