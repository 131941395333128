import React from "react";

import { Button, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";
import { TAGHERE_TALLY_BUTTON } from "@/consts/ui";
import { useTallyLink } from "@/hooks/useTallyLink";

const Section14_1 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { tallyLink } = useTallyLink();

    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                paddingY: isMobile ? "80px" : "50px",
                paddingX: isMobile ? "80px" : "120px",
                backgroundColor: tmrColorPalette.primary[500],
                maxWidth: "100vw",
                marginY: 0,
            }}
        >
            <Typography
                variant={isMobile ? "f32B" : "f36B"}
                sx={{
                    color: tmrColorPalette.grey[900],
                    whiteSpace: "pre-wrap",
                    lineHeight: 1.5,
                }}
            >
                {isMobile ? "태그히어로\n편하게 주문받기" : "태그히어로 편하게 주문받기"}
            </Typography>
            <Typography
                variant="f20M"
                sx={{
                    color: tmrColorPalette.grey[900],
                    whiteSpace: "pre-wrap",
                    mt: "16px",
                    lineHeight: 1.5,
                }}
            >
                {"담당 전문가가 1:1 상담을 도와드려요"}
            </Typography>
            <Button
                id={"goTallyButton"}
                sx={{
                    backgroundColor: tmrColorPalette.grey[900],
                    borderRadius: "100px",
                    padding: "15px 20px",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                    lineHeight: 1.5,
                    color: "#fff",
                    mt: "30px",

                    height: "54px",
                    "&:hover": {
                        backgroundColor: "#000",
                    },
                }}
                href={tallyLink}
                target={"_blank"}
            >
                <Typography
                    id={"goTallyButton"}
                    variant="f18B"
                    sx={{
                        color: tmrColorPalette.white[100],
                        lineHeight: 1.5,
                    }}
                >
                    {TAGHERE_TALLY_BUTTON}
                </Typography>
            </Button>
        </LandingLayout>
    );
};

export default Section14_1;
