/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 채널톡 래핑클래스
 */
class ChannelService {
    constructor() {
        this.loadScript();
    }

    loadScript() {
        const w = window as any;
        if (w.ChannelIO) {
            return;
        }
        const ch: any = function () {
            // eslint-disable-next-line prefer-rest-params
            ch.c(arguments);
        };
        ch.q = [];
        ch.c = function (args: any) {
            ch.q.push(args);
        };
        w.ChannelIO = ch;
        const l = () => {
            if (w.ChannelIOInitialized) {
                return;
            }
            w.ChannelIOInitialized = true;
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
            // s.charset = "UTF-8"; // 문서에서 인코딩을 상속하므로 실은 필요가 없음
            const x = document.getElementsByTagName("script")[0];
            x.parentNode?.insertBefore(s, x);
        };

        if (document.readyState === "complete") {
            l();
        } else if (w.attachEvent) {
            //IE<=8 instead only supports the proprietary .attachEvent() method.
            w.attachEvent("onload", l);
        } else {
            window.addEventListener("DOMContentLoaded", l, false);
            window.addEventListener("load", l, false);
        }
    }

    boot(settings: any) {
        const w = window as any;
        w.ChannelIO("boot", settings);
    }

    shutdown() {
        const w = window as any;
        w.ChannelIO("shutdown");
    }
}

export default ChannelService;
