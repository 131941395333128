import { useEffect, useState } from "react";

import { Backdrop, Box, Typography, Zoom, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import ChannelService from "../../utils/ChannelService";

const ChannelTalkComponent = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const [showInstruction, setShowInstruction] = useState(false);

    useEffect(() => {
        const channelTalk = new ChannelService();

        channelTalk.boot({
            pluginKey: process.env["NEXT_PUBLIC_CHANNEL_IO"],
        });

        return () => {
            channelTalk.shutdown();
        };
    }, []);

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: isMobile ? 80 : 100,
                right: isMobile ? 50 : 70,
                zIndex: 14,
            }}
        >
            <Backdrop open={showInstruction} onClick={() => setShowInstruction(false)} />
            <Zoom in={showInstruction}>
                <Box
                    sx={{
                        position: "fixed",
                        width: isMobile ? 200 : 300,
                        height: isMobile ? 200 : 300,
                        right: isMobile ? -100 : -150,
                        bottom: isMobile ? -100 : -150,
                        borderRadius: "50%",
                        transition: "ease-out 0.2s",
                        bgcolor: "rgba(255,255,255,0.4)",
                        border: "2px dashed #F9BD38",
                    }}
                />
            </Zoom>
            <Zoom in={showInstruction}>
                <Box
                    sx={{
                        position: "relative",
                        width: 65,
                        margin: "0 auto",
                        transition: "ease-out 0.2s",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: -20,
                            left: isMobile ? -70 : -50,
                        }}
                    >
                        <Typography variant="f16B" sx={{ color: "#F9BD38", whiteSpace: "pre" }}>
                            {"폼 신청 후, 채팅으로 빠르게\n상담을 받아보실 수 있어요!"}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            border: "2px dashed #F9BD38",
                            borderColor: "transparent transparent transparent #F9BD38",
                            height: 180,
                            width: 600,
                            borderRadius: "230px 0 0 150px",
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            left: 23,
                            top: 160,
                            ":before": {
                                border: "1px dashed #F9BD38",
                                height: 25,
                                content: '""',
                                position: "absolute",
                                top: -11,
                                left: -11,
                                transform: "rotate(-74deg)",
                                WebkitTransform: "rotate(-74deg)",
                                msTransform: "rotate(-74deg)",
                                ["MozTransform" as any]: "rotate(-74deg)",
                            },
                            ":after": {
                                border: "1px dashed #F9BD38",
                                height: 25,
                                content: '""',
                                position: "absolute",
                                top: -20,
                                left: 5,
                                transform: "rotate(12deg)",
                                WebkitTransform: "rotate(12deg)",
                                msTransform: "rotate(12deg)",
                                ["MozTransform" as any]: "rotate(12deg)",
                            },
                        }}
                    />
                </Box>
            </Zoom>
        </Box>
    );
};

export default ChannelTalkComponent;
